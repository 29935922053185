import { Maybe } from '@/shared';
import { useLoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import {
	createContext,
	FC,
	PropsWithChildren,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { Loader } from '@/shared/utils/loader/loader';
import AutocompleteService = google.maps.places.AutocompleteService;
import Geocoder = google.maps.Geocoder;
import { useCulture } from '@/config';

type GoogleMapProperties = {
	loader: Loader;
	autoCompleteService: Maybe<AutocompleteService>;
	geocoderService: Maybe<Geocoder>;
};

const googleMapContext = createContext<GoogleMapProperties>({
	autoCompleteService: null,
	geocoderService: null,
	loader: {
		loading: true
	}
});

export const useGoogleMapScripts = (): GoogleMapProperties => useContext(googleMapContext);

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;
const libraries: Libraries = ['geometry', 'places'];

export const GoogleMapProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const { culture } = useCulture();
	const [autocompleteService, setAutocompleteService] =
		useState<Maybe<AutocompleteService>>(null);

	const [gecoderService, setGecoderService] = useState<Maybe<Geocoder>>(null);

	const { isLoaded } = useLoadScript({
		id: 'google-map-script',
		googleMapsApiKey: GOOGLE_MAPS_API_KEY,
		libraries: libraries,
		language: culture.split('_')[0] || 'en'
	});

	useEffect(() => {
		if (isLoaded && !autocompleteService) {
			setAutocompleteService(new window.google.maps.places.AutocompleteService());
		}
		if (isLoaded && !gecoderService) {
			setGecoderService(new window.google.maps.Geocoder());
		}
	}, [isLoaded, autocompleteService, gecoderService]);

	const contextValue = useMemo<GoogleMapProperties>(
		() => ({
			autoCompleteService: autocompleteService,
			geocoderService: gecoderService,
			loader: {
				loading: !isLoaded,
				finished: !isLoaded
			}
		}),
		[autocompleteService, gecoderService, isLoaded]
	);
	return <googleMapContext.Provider value={contextValue}>{children}</googleMapContext.Provider>;
};
