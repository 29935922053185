import {
	areGoogleMapSuggestionsEqual,
	GoogleMapSuggestion,
	isItemAutocompletePrediction,
	isLoading,
	isNil,
	Maybe
} from '@/shared';
import { useGoogleMapSuggestions } from '@/ui/autocomplete/google-map/helpers/useGoogleMapSuggestions';
import Autocomplete from '@mui/material/Autocomplete';
import { FC, ReactNode, SyntheticEvent, useState } from 'react';
import { GoogleMapAutocompleteOption } from '@/ui/autocomplete/google-map/helpers/GoogleMapAutocompleteOption';
import { OverridableStringUnion } from '@mui/types';
import { TextFieldPropsSizeOverrides } from '@mui/material/TextField/TextField';
import { Input } from '@/ui/input/Input';
import { CircularProgress } from '@mui/material';
import PinStroke from '@/assets/icons/generated/stroke/PinStroke';
import { PaperProps } from '@mui/material/Paper';

export type SearchType = 'cities' | 'places';

export type GoogleMapAutocompleteProps = {
	onChange?: (value: Maybe<GoogleMapSuggestion>) => void;
	inputPlaceholder?: string;
	size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
	id?: string;
	searchType?: SearchType;
	value?: GoogleMapSuggestion;
	includeInputInList?: boolean;
	onPromptStateChange?: (value: boolean) => void;
	paperProps?: PaperProps;
	disabled?: boolean;
	error?: boolean;
};
export const GoogleMapAutocomplete: FC<GoogleMapAutocompleteProps> = ({
	inputPlaceholder,
	size = 'medium',
	onChange = () => {},
	id,
	searchType = 'cities',
	value,
	includeInputInList = true,
	onPromptStateChange = () => {},
	paperProps = {},
	disabled = false,
	error = false
}) => {
	const [query, setQuery] = useState<string>();

	const { options: googleMapOptions, loader } = useGoogleMapSuggestions(
		query,
		searchType,
		includeInputInList
	);
	const options: GoogleMapSuggestion[] = !isNil(value)
		? [value, ...googleMapOptions]
		: googleMapOptions;

	return (
		<Autocomplete
			id={id || 'GoogleMapAutocompleteId'}
			disabled={disabled}
			options={options}
			autoComplete
			freeSolo
			includeInputInList={includeInputInList}
			getOptionLabel={(option: GoogleMapSuggestion) =>
				isItemAutocompletePrediction(option)
					? option.structured_formatting.main_text
					: option
			}
			value={value || ''}
			onInputChange={(_, newQuery) => setQuery(newQuery)}
			isOptionEqualToValue={(receivedOption, receivedValue) =>
				areGoogleMapSuggestionsEqual(receivedOption, receivedValue)
			}
			onChange={(event: SyntheticEvent, prediction: Maybe<GoogleMapSuggestion>) => {
				onChange(prediction);
			}}
			renderOption={(props, option: GoogleMapSuggestion, state) => (
				<GoogleMapAutocompleteOption
					key={state.index}
					suggestion={option}
					htmlProps={props}
				/>
			)}
			popupIcon={isLoading([loader]) && <CircularProgress color="primary" size={20} />}
			loading={isLoading([loader])}
			onOpen={() => onPromptStateChange(true)}
			onClose={() => {
				onPromptStateChange(false);
			}}
			slotProps={{
				paper: {
					...paperProps,
					sx: {
						marginY: 1,
						...paperProps.sx
					}
				}
			}}
			renderInput={(params) => (
				<Input
					{...params}
					size={size}
					placeholder={inputPlaceholder}
					leftIcon={PinStroke}
					error={error}
					InputLabelProps={{
						children: params.InputLabelProps.children as ReactNode
					}}
				/>
			)}
		/>
	);
};
