import { ProblemDetail } from '@/api';
import { Client } from '@hey-api/client-fetch';

type RequestResult = Awaited<ReturnType<Client['request']>>;

export const handleApiError = <T>(requestResult: RequestResult) => {
	if (requestResult.error) {
		if (requestResult.error satisfies ProblemDetail) {
			throw new Error((requestResult.error as ProblemDetail).detail);
		}
		throw new Error('API error occurred.');
	}
	return {
		request: requestResult.request,
		response: requestResult.response,
		data: requestResult.data as T
	};
};
