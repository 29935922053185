import { FC, HTMLAttributes } from 'react';
import parse from 'autosuggest-highlight/parse';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
	GoogleMapSuggestion,
	isItemAutocompletePrediction,
	isSuggestionAutocompletePrediction
} from '@/shared';
import { IconButton } from '@/ui/icon-button/IconButton';
import { primary } from '@/ui/theme/components-overrides/colors';
import SvgPinStroke from '@/assets/icons/generated/stroke/PinStroke';
import PredictionSubstring = google.maps.places.PredictionSubstring;

type GoogleMapAutocompleteOptionProps = {
	suggestion: GoogleMapSuggestion;
	htmlProps: HTMLAttributes<HTMLLIElement>;
};

export const GoogleMapAutocompleteOption: FC<GoogleMapAutocompleteOptionProps> = ({
	suggestion,
	htmlProps
}) => {
	const matches = isItemAutocompletePrediction(suggestion)
		? suggestion.structured_formatting.main_text_matched_substrings
		: [];

	const parts = isItemAutocompletePrediction(suggestion)
		? parse(
				suggestion.structured_formatting.main_text,
				matches.map((match: PredictionSubstring) => [
					match.offset,
					match.offset + match.length
				])
		  )
		: [
				{
					text: suggestion,
					highlight: false
				}
		  ];
	return (
		<li {...htmlProps}>
			<Grid container alignItems="center">
				<Grid item sx={{ display: 'flex', width: 44 }}>
					<IconButton variant={'secondary'}>
						<SvgPinStroke width={'16px'} height={'16px'} color={primary.main} />
					</IconButton>
				</Grid>
				<Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
					{parts.map((part, index) => (
						<Box
							key={index}
							component="span"
							sx={{
								fontWeight: part.highlight ? 'bold' : 'regular'
							}}>
							{part.text}
						</Box>
					))}
					<Typography variant="body2" color="text.secondary">
						{isSuggestionAutocompletePrediction(suggestion)
							? suggestion.structured_formatting.secondary_text
							: suggestion}
					</Typography>
				</Grid>
			</Grid>
		</li>
	);
};
