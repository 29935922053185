import { TrainerQuery } from '@/shared/repository/trainer/trainer-api-types';
import { useQuery } from '@tanstack/react-query';
import { useCulture } from '@/config';
import { list, PageResponseTrainerResponse } from '@/api';
import { isNil } from '@/shared';
import { mapTrainer } from '@/shared/repository/trainer-mapper';
import { handleApiError } from '@/shared/utils/helpers/http/handle-api-error';

const TRAINERS_QUERY_KEY = 'trainers-query';

export const useTrainers = (query: TrainerQuery) => {
	const { culture } = useCulture();

	const { data, isLoading } = useQuery({
		queryKey: [TRAINERS_QUERY_KEY, query],
		queryFn: async () => {
			const response = await list({
				query: {
					ids: isNil(query.ids) ? undefined : query.ids,
					name: isNil(query.name) ? undefined : query.name,
					languages:
						isNil(query.languages) || query.languages.length === 0
							? undefined
							: query.languages,
					discipline: isNil(query.discipline) ? undefined : query.discipline,
					placeId: isNil(query.placeId) ? undefined : query.placeId,
					startTime: isNil(query.startTime) ? undefined : query.startTime.toISOString(),
					endTime: isNil(query.endTime) ? undefined : query.endTime.toISOString(),
					priceMin: isNil(query.priceMin) ? undefined : query.priceMin,
					priceMax: query.priceMax || undefined,
					sort: isNil(query.sort) ? undefined : query.sort,
					pageNumber: isNil(query.pageNumber) ? undefined : query.pageNumber,
					pageSize: isNil(query.pageSize) ? undefined : query.pageSize,
					contentLanguage: culture
				}
			});
			const { data: pagedTrainers } = handleApiError<PageResponseTrainerResponse>(response);
			const trainers = pagedTrainers.content?.map((trainer) => mapTrainer(trainer)) || [];
			return {
				content: trainers,
				pageNumber: pagedTrainers.pageNumber,
				pageSize: pagedTrainers.pageSize,
				totalElements: pagedTrainers.totalElements,
				totalPages: pagedTrainers.totalPages,
				hasMore: pagedTrainers.hasMore
			};
		}
	});

	return { trainers: data, isLoading };
};
