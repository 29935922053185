import {
	DisciplineOrTrainerAutocompleteItem,
	disciplines,
	getDisciplineMetaData,
	isNil,
	Loader,
	Maybe
} from '@/shared';
import { useTranslation } from '@/config';
import { useTrainers } from '@/shared/repository/trainer/use-trainers';

type DisciplineOrTrainersAutocompleteOptionsProperties = {
	options: Maybe<DisciplineOrTrainerAutocompleteItem[]>;
	loader: Loader;
};

export const useDisciplineOrTrainersAutocompleteOptions = (
	query: Maybe<string>
): DisciplineOrTrainersAutocompleteOptionsProperties => {
	const { t } = useTranslation();
	const { trainers, isLoading: trainersLoading } = useTrainers({ name: query });
	const disciplineSuggestions = isNil(query)
		? disciplines
		: disciplines.filter((d) => {
				const disciplineTranslation = t(getDisciplineMetaData(d).translationKey);
				return disciplineTranslation.toLowerCase().includes(query.toLowerCase());
		  });

	return {
		options: isNil(trainers)
			? [...disciplineSuggestions]
			: [...disciplineSuggestions, ...trainers.content],
		loader: { loading: trainersLoading }
	};
};
